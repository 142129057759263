import {ActionsUnion, createAction} from "../../redux/customAction";
import { Deal } from "../models/deal.interface";
export const DEALS_RETRIEVING = 'DEALS_RETRIEVING'
export const DEALS_RETRIEVED = 'DEALS_RETRIEVED'
export const Actions = {
    dealsRetrieved: (deals: Deal[]) =>
        createAction(DEALS_RETRIEVED, deals),
    dealsRetrieving: () => 
        createAction(DEALS_RETRIEVING)
};
export type ActionsType = ActionsUnion<typeof Actions>;
