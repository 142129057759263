import {AppState} from "../redux/appState.interface";
import {createSelector} from "reselect";
import { Deal } from "../corelogic/models/deal.interface"
import { RequestState } from "../corelogic/models/requestState.interface";

export interface DealVM extends Deal {
    contactWebsiteUrlWithProtocol: string,
    contactWebsiteUrlWithoutProtocol: string
}

export const dealsList = createSelector<
    AppState,
    Deal[],
    RequestState,
    { deals: DealVM[], isLoading: Boolean}>(
    state => state.deals.data,
    state => state.deals.state,
    (deals, state) => {
        return {
            deals: deals.map(deal => ({
                ...deal,
                contactWebsiteUrlWithProtocol: withhttps(deal.contactWebsite),
                contactWebsiteUrlWithoutProtocol: withouthttps(deal.contactWebsite)
            })),
            isLoading: state === RequestState.idle || state === RequestState.processing
        };
    }
)

const withhttps = (url: string) => url && (!/^(?:f|ht)tps?\:\/\//.test(url) ? `https://${url}` : url)
const withouthttps = (url: string) => url && url.replace(/^https?:\/\//,'')