import React, {useEffect, useState, FunctionComponent} from "react"
import {useDispatch, useSelector} from "react-redux";
import {SvgIcons, Icons} from "../components/svg/icons"
import {dealsList, DealVM} from "../selector/dealsList.selector"
import {retrieveDeals} from "../corelogic/usecases/retrieve-deals/retrieveDeals"
import {Deal} from "../corelogic/models/deal.interface"
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet"

const DealPicturePlaceHolder = () => (
  <div className="w-full h-96 bg-gray-200 text-center pt-28">
      <div className="w-32 h-32 text-gray-300 mx-auto"> <SvgIcons icon={Icons.PhotgrapherSvg}/> </div>
  </div>
)

const DealCard: FunctionComponent<{deal: DealVM}> = ({deal}) => (
    <div id={deal.key} className={'rounded-md bg-white border border-gray-300 mt-2 md:mt-10 ' + (deal.expired ? 'grayscale-1' : '')} >
        <div className="w-full">
          <div className="text-xl mt-2 px-4 uppercase"> <a href={'#'+deal.key}> {deal.hotelName}  </a> </div>
          <div className="text-sm px-4 text-gray-600"> {deal.hotelDescription} </div>
        </div>
        <div className="bg-cover bg-center w-full pt-2 mt-2 relative">
            <LazyLoadComponent placeholder={<DealPicturePlaceHolder/>}>
              <figure className="stinson"> <img className="object-cover w-full h-44 md:h-96" src={deal.hotelPicture} /> </figure>
            </LazyLoadComponent>
            { deal.expired && (<div className="absolute top-2 right-2">
                <div
                  className="ml-4 mt-5 h-5 text-xs inline-flex items-center px-3 py-1 bg-gray-900 text-gray-200 rounded-full"
                >
                  cette offre est expirée
                </div>
              </div>)
          }
        </div>
        <div className="w-full min-h-20 bg-brand-500 hover:bg-brand-600 cursor-pointer px-4 pt-2 text-left sm:text-right transition duration-500 pb-4">
            <div className="font-sans font-bold text-md md:text-xl tracking-wider text-white"> {deal.title} </div>
            <div className="font-sans text-sm text-white"> {deal.subtitle} </div>
        </div>
        <div className="mt-2 px-4">
        <div className="text-gray-800 text-md md:text-lg flex items-center"> <div className="mr-1 w-6 text-gray-800 inline-block h-6"><SvgIcons icon={Icons.CalendarSvg}/></div> <span className="pt-1"> {deal.dateDescription} </span></div>
        </div>
        <div className="w-full px-4 pt-2">
            <div className="text-gray-800 text-sm md:text-md" dangerouslySetInnerHTML={{__html: deal.descriptionHTML}} />
        </div>
        <div className="px-4 pt-2">
            { deal.contactPhone && (<div className="flex items-center cursor-pointer hover:text-brand-500  text-gray-700 "> <div className="w-4 inline-block h-4 mr-2"><SvgIcons icon={Icons.PhoneSvg}/></div> <a href={'tel:'+deal.contactPhone} className="text-sm"> {deal.contactPhone} </a>  </div>) }
            { deal.contactEmail && (<div className="flex items-center cursor-pointer hover:text-brand-500  text-gray-700 "> <div className="w-4 inline-block h-4 mr-2"><SvgIcons icon={Icons.MailSvg}/></div> <a href={'maito:'+deal.contactEmail} className="text-sm"> {deal.contactEmail} </a>  </div>) }
            { deal.contactWebsite && (<div className="flex items-center cursor-pointer hover:text-brand-500 text-gray-700"> <div className="w-4 inline-block h-4 mr-2"><SvgIcons icon={Icons.GlobeSvg}/></div> <a href={deal.contactWebsiteUrlWithProtocol} target="_blank" className=" text-sm"> {deal.contactWebsiteUrlWithoutProtocol} </a>  </div>) }
        </div>
        <div className="mt-4 px-4 flex flex-row items-center pb-2 w-full text-right">
            <div className="text-gray-400 text-xs ml-1 w-full cursor-pointer hover:text-brand-500"> <a href={deal.offerLink} target="_blank"> <span> voir le post original sur Facebook </span> <div className="ml-1 pt-1 w-4 inline-block h-4"> <SvgIcons icon={Icons.ExternalLinkSvg}/> </div>  </a>  </div>
        </div>
    </div>
)

export default function Home() {

  const {deals, isLoading} = useSelector(dealsList);
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(retrieveDeals);
  }, [dispatch]);


  return (
    <div className="bg-gray-100 w-full">

      <Helmet>
        <meta charSet="utf-8" />
        <title>iDeal Hotel - Les meilleurs offres du moment</title>
        <meta name="description" content="Les meilleurs deals dans les plus beaux hôtels et hébergement de la Réunion sont ici." />
      </Helmet>

      <div className="w-full px-10 py-4 bg-brand-500 text-3xl font-bolder text-white text-center md:text-left flex flex-row items-end">
        <img src="/logo.png" className="h-16"/>
        <div className="ml-2 -mb-1"> iDeal Hotel </div>
      </div>

      <div className="px-1 sm:px-5 pt-2 md:pt-10 w-full">

      <div className="w-full font-black mb-2 text-3xl text-gray-900 mt-1 px-2">
        Notre sélection juste pour vous
      </div>

      <div className="text-md mb-5 text-gray-700 px-2"> Nous avons choisi pour vous les plus belles offres du moment dans les plus beaux hébergements de la Réunion </div>

      <div className="w-full mt-5 md:max-w-screen-md md:mx-auto">
          {isLoading && ([0, 1, 2].map( _ => (
              <div className="rounded-md bg-gray-100 border border-gray-300 mt-2 md:mt-10 animate-pulse">
                  <div className="w-full px-4 mt-4 space-y-2 ">
                     <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                     <div className="h-4 bg-gray-200 rounded w-1/4"></div>
                  </div>
                  <div className="bg-cover bg-center w-full pt-2 mt-2 relative">
                      <div className="w-full h-96 bg-gray-200 text-center pt-28">
                          <div className="w-32 h-32 text-gray-300 mx-auto"> <SvgIcons icon={Icons.PhotgrapherSvg}/> </div>
                      </div>
                  </div>
                  <div className="w-full h-20 bg-gray-300"/>
                  <div className="mt-2 px-4">
                  </div>
                  <div className="w-full px-4 pt-2">
                  <div className="space-y-2 mb-4">
                    <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                    <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/3"></div>
                  </div>
                  </div>
              </div>
            ))
          )}
          {deals.map(deal => <DealCard key={deal.key} deal={deal} />)}
      </div>

      </div>

    </div>
  )
}
