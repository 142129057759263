import * as actionCreators from "../actionCreators";
import {ThunkResult} from "../../../redux/configureStore";


export const retrieveDeals: ThunkResult<Promise<void>> = async (dispatch,
                                                                     state,
                                                                     {ApiGateway}) => {
    dispatch(actionCreators.Actions.dealsRetrieving())                                                                  
    const deals = await ApiGateway.retrieveDeals()
    dispatch(actionCreators.Actions.dealsRetrieved(deals))
}
